.root {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 0px;
}
.root > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .root > * {
    grid-column: 4;
  }
}
.section___4Yll8 {
  display: grid;
  position: relative;
  width: 100%;
  height: 90px;
  max-width: 100%;
  align-content: flex-start;
  justify-items: center;
  margin-bottom: 80px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 14px 0px;
}
.section___4Yll8 > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section___4Yll8 {
    padding-left: 0px;
  }
}
@media (min-width: 768px) {
  .section___4Yll8 > * {
    grid-column: 4;
  }
}
.navigationBar:global(.__wab_instance) {
  width: 100%;
  display: flex;
  margin-bottom: 0px;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 0px;
  padding: 16px;
}
.link__alCkN {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.img__gZgrb {
  object-fit: cover;
  height: 91px;
}
.img__gZgrb > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__gZgrb {
    max-width: 159px;
    width: 100%;
    margin-bottom: 0px;
    height: 145px;
    min-width: 0;
  }
}
.img___04KTg {
  object-fit: cover;
}
.img___04KTg > picture > img {
  object-fit: cover;
}
.img__g8Di {
  object-fit: cover;
}
.img__g8Di > picture > img {
  object-fit: cover;
}
.link__joVUr {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #0070f3;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
@media (min-width: 768px) {
  .link__joVUr {
    padding-right: 0px;
  }
}
.section___7XZ6R {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 13px;
  margin-top: 0px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section___7XZ6R > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section___7XZ6R {
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  .section___7XZ6R > * {
    grid-column: 4;
  }
}
.globalWarming:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 380px;
  height: 247px;
  margin-top: 0px;
}
@media (min-width: 768px) {
  .globalWarming:global(.__wab_instance) {
    width: 946px;
    height: 453px;
    margin-top: 0px;
    margin-bottom: 30px;
  }
}
.reveal:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__gNmgo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 0px;
  margin-bottom: 12px;
  text-transform: none;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.section__jcrZe {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: 38px;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  margin-bottom: 43px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__jcrZe > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__jcrZe > * {
    grid-column: 4;
  }
}
.h1__ngjVc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  font-size: 64px;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.text__tmbK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 0px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.img__yqicw {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  margin-top: 14px;
}
.img__yqicw > picture > img {
  object-fit: cover;
}
.columns {
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  min-width: 0;
  display: none;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .columns > :nth-child(4n + 1) {
    width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
  }
}
@media (min-width: 768px) {
  .columns > :nth-child(4n + 2) {
    width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
  }
}
@media (min-width: 768px) {
  .columns > :nth-child(4n + 3) {
    width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
  }
}
@media (min-width: 768px) {
  .columns > :nth-child(4n + 4) {
    width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
  }
}
.column___0HJSr {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.h1__eYlEh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 1em;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-align: left;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.text__rkHsQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.column__forru {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.h1__iqMAj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 1em;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-align: left;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.text__ejiob {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.column__bHcaZ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.h1__xwFhj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 1em;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-align: left;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.text__jwOn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.column__pDc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.h1__hd5Xm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 1em;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-align: left;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.text__fObC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.section__hxlgV {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: 35px;
  margin-bottom: 52px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__hxlgV > * {
  grid-column: 4;
}
.h1__xtutN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 50px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .h1__xtutN {
    font-size: 64px;
  }
}
.lottie:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__a03XW {
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 50px;
  min-width: 0;
  padding: 8px;
}
.freeBox__qEVy {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.card__yqCm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__x6Y0M {
  padding-right: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__zUFra {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.freeBox__rBxim {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.card__utxln:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__o3PcO {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__kOxYc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.freeBox___3Tn6M {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.card___5SrUe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___0YZ5 {
  height: auto;
  padding-right: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__ug1E0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.freeBox__rcepH {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.card___6QhdF:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__wlKkv {
  padding-right: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__ak4X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.section__kFfM0 {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: 30px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__kFfM0 > * {
  grid-column: 4;
}
.h1__dh4Xj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 24px;
  font-size: 50px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .h1__dh4Xj {
    font-size: 64px;
  }
}
.youTube:global(.__wab_instance) {
  width: 640px;
  height: 390px;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.section__ndEi6 {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: 48px;
  margin-bottom: 23px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__ndEi6 > * {
  grid-column: 4;
}
.h1__tfyCb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 20px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.freeBox__dHgBo {
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__xTb21 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__jk5Ld {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__jk5Ld > picture > img {
  object-fit: cover;
}
.freeBox__s3T {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: flex-end;
  min-width: 0;
  padding: 8px;
}
.img__yVTmZ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  align-self: center;
}
.img__yVTmZ > picture > img {
  object-fit: cover;
}
.freeBox___12RkW {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__diFj9 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__diFj9 > picture > img {
  object-fit: cover;
}
.freeBox__cvy0P {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__dSydM {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__dSydM > picture > img {
  object-fit: cover;
}
.freeBox__rKhMe {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img___5AfD2 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img___5AfD2 > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img___5AfD2 {
    height: 179px;
    flex-shrink: 0;
  }
}
.freeBox__ttbNz {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__mt7FZ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__mt7FZ > picture > img {
  object-fit: cover;
}
.section__vJrXq {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 18px;
  margin-top: 30px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__vJrXq > * {
  grid-column: 4;
}
.h1__jObje {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 24px;
  font-size: 50px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .h1__jObje {
    font-size: 64px;
  }
}
.img__l9Ufz {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__l9Ufz > picture > img {
  object-fit: cover;
}
.text__a2Smp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.freeBox__dpp0U {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__mk1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__r3Ww8 {
  position: relative;
  object-fit: contain;
  max-width: 10em;
  height: 10em;
  flex-shrink: 0;
  border-radius: 0em;
}
.img__r3Ww8 > picture > img {
  object-fit: contain;
}
.text__d3OYe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.link__bfR9R {
  position: relative;
  display: flex;
}
.svg__zjQiO {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.freeBox__sbkss {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img___0DsEg {
  object-fit: contain;
  max-width: 10em;
  height: 10em;
  position: relative;
  flex-shrink: 0;
  border-radius: 0em;
}
.img___0DsEg > picture > img {
  object-fit: contain;
}
.text__x6Sl1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.link__x5Rv {
  position: relative;
  display: flex;
}
.svg___1HpBd {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.freeBox__sxYfe {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__w2IXj {
  position: relative;
  object-fit: contain;
  max-width: 10em;
  height: 10em;
  flex-shrink: 0;
  border-radius: 0em;
}
.img__w2IXj > picture > img {
  object-fit: contain;
}
.text__ubO1M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.link__thIj {
  position: relative;
  display: flex;
}
.svg__trzQ1 {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.section__fJoSx {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 25px;
  margin-top: 40px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__fJoSx > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__fJoSx > * {
    grid-column: 4;
  }
}
.h1__iDnQo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 24px;
  font-size: 50px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .h1__iDnQo {
    font-size: 64px;
  }
}
.freeBox__pgQGe {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__uq8Yj {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.link__ykPcw {
  position: relative;
  display: flex;
}
.card__pQi80:global(.__wab_instance) {
  max-width: 100%;
}
.text__ukUIe {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.img__pwSur {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__pwSur > picture > img {
  object-fit: cover;
}
.text__qf9Id {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.freeBox__u2SaW {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.link__x34KR {
  position: relative;
  display: flex;
}
.card__xHm5:global(.__wab_instance) {
  max-width: 100%;
}
.text__jCjNn {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.img__tvCoY {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__tvCoY > picture > img {
  object-fit: cover;
}
.text___0BtV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.freeBox__fhNv {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.link__kmZF {
  position: relative;
  display: flex;
}
.card___527CM:global(.__wab_instance) {
  max-width: 100%;
}
.text__xXwaq {
  height: auto;
  padding-bottom: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__va29T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__whazc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.freeBox__pzvXd {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.link__tsCkY {
  position: relative;
  display: flex;
}
.card__vOloi:global(.__wab_instance) {
  max-width: 100%;
}
.text__dsfyG {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__pysjw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__jqSa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.freeBox__diwF8 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.link__zqBew {
  position: relative;
  display: flex;
}
.card___3PGnn:global(.__wab_instance) {
  max-width: 100%;
}
.text___7MY3 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__xdhnf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__aXLpC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.freeBox__oauJ8 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.link__aomrz {
  position: relative;
  display: flex;
}
.card__bIq4R:global(.__wab_instance) {
  max-width: 100%;
}
.text__i9N2M {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.img__b0F43 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  margin-bottom: 0px;
}
.img__b0F43 > picture > img {
  object-fit: cover;
}
.text__p0U3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  margin-bottom: 0px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.section___5MB6 {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 38px;
  margin-top: 26px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section___5MB6 > * {
  grid-column: 4;
}
.h1___05JZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 24px;
  font-size: 50px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .h1___05JZ {
    font-size: 64px;
  }
}
.accordion:global(.__wab_instance) {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
}
.accordionItem__pVxuc:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text___8RBq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__jHMad {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.accordionItem__f2By:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__viYDz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  min-width: 0;
}
.text__xof6Q {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-bottom: 0px;
  min-width: 0;
}
.accordionItem__z93Q4:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__jqA06 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__blfAw {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
}
.accordionItem__vW5Hx:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__ebUg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 0px;
  min-width: 0;
}
.text__lAtup {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.section___5BRdf {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section___5BRdf > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section___5BRdf > * {
    grid-column: 4;
  }
}
.freeBox__yeacg {
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 128px 16px;
}
.freeBox__yeacg > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__yeacg > :global(.__wab_flex-container) > *,
.freeBox__yeacg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__yeacg > :global(.__wab_flex-container) > picture > img,
.freeBox__yeacg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.freeBox__eeY4 {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 1080px;
}
.freeBox__eeY4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__eeY4 > :global(.__wab_flex-container) > *,
.freeBox__eeY4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__eeY4 > :global(.__wab_flex-container) > picture > img,
.freeBox__eeY4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.text__swoMa {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 800;
  font-style: normal;
  color: #000000d9;
  text-align: center;
  text-transform: none;
  line-height: 1;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  padding-bottom: 0px;
}
.text___2CZxC {
  position: relative;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #000000d9;
  text-align: center;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
@media (min-width: 768px) {
  .text___2CZxC {
    text-align: center;
  }
}
.link__nldN9 {
  position: relative;
  display: flex;
}
.button2__zKt1R:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ezQrq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___0Mn8X {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #ededec;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  margin-bottom: 0px;
}
.svg__nRoBm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.section__dAvv7 {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__dAvv7 > * {
  grid-column: 4;
}
.h1__tDYpX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 24px;
  font-size: 50px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .h1__tDYpX {
    font-size: 65px;
  }
}
.freeBox__qQmdQ {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__qQmdQ > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__qQmdQ > :global(.__wab_flex-container) > *,
.freeBox__qQmdQ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qQmdQ > :global(.__wab_flex-container) > picture > img,
.freeBox__qQmdQ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.link__nqxck {
  position: relative;
  display: flex;
}
.button2__j9ZK:global(.__wab_instance) {
  max-width: 100%;
}
.svg__c0FL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___6GDsW {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #ededec;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.svg__hjckx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.link__x8V3S {
  position: relative;
  display: flex;
}
.button2__vfy6K:global(.__wab_instance) {
  max-width: 100%;
}
.svg__oRlHg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___6Er {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #ededec;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.svg___5RhG8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__wNNlq {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #000000;
  min-width: 0;
  padding: 8px;
}
.text__an6L0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 0.7em;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.link__sjsat {
  position: relative;
  display: flex;
}
.svg__xfxGs {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.link__tQh14 {
  position: relative;
  display: flex;
}
.svg__pdUmE {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.link__iXheq {
  position: relative;
  display: flex;
}
.svg__srgHw {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.link___6Kqkt {
  position: relative;
  display: flex;
}
.svg___9WlHk {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.link__bcFXm {
  position: relative;
  display: flex;
}
.svg__tHzvd {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
